export function serialize(data) {

    return {
        'air:amadeus_content_control': {
            'ndc_carriers': data.all_carriers ? [] : data.ndc_carriers,
            'is_ndc_enabled': data.is_ndc_enabled,
            'excluded_carriers': data.excluded_carriers,
        },
    };
}

export function parse(raw) {
    const value = raw['air:amadeus_content_control'] || {};
    const ndcCarriers = value.ndc_carriers || [];
    return {
        'ndc_carriers': ndcCarriers,
        'all_carriers': !ndcCarriers.length,
        'is_ndc_enabled': value.is_ndc_enabled,
        'excluded_carriers': value.excluded_carriers || [],
    };
}
