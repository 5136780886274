import noop from 'lodash/noop';
import first from 'lodash/first';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {injectField} from 'midoffice/newforms/decorators';
import gettext from 'airborne/gettext';

const PASS_PROPS = [
    'required', 'name', 'onFocus', 'label', 'disabled', 'readOnly', 'data-testid', 'autoComplete'
];


@injectField
export default class Select extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.any,
        placeholder: PropTypes.string,
        choices: PropTypes.array,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        inputSize: PropTypes.string,
        plain: PropTypes.bool,
        groups: PropTypes.bool,
        transparent: PropTypes.bool,
        style: PropTypes.object,
        withDescription: PropTypes.bool,

        'data-testid': PropTypes.string,

        allowEmpty: PropTypes.bool,
        emptyValue: PropTypes.string,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func
    };

    static defaultProps = {
        choices: [],
        className: '',
        emptyValue: '',
        allowEmpty: false,
        disabled: false,
        readOnly: false,
        inputSize: 'max',
        plain: false,
        onBlur: noop,
        onFocus: noop
    };

    state = {focused: false};

    focus() {
        this.input.focus();
    }

    getChoices() {
        const {allowEmpty, choices, emptyValue, placeholder=gettext('Select')} = this.props;
        return allowEmpty
            ? [[emptyValue, `— ${placeholder} —`, '--'], ...choices]
            : choices;
    }

    /*
     * Return select value to display.
     * If value is missed from choices and empty allowed - render empty value.
     */
    getValueDisplay() {
        const {emptyValue, allowEmpty} = this.props;
        let value = this.props.value;

        if (value === null || value === undefined) { value = emptyValue; }   // eslint-disable-line no-undefined

        let choice = this.getChoices().find(([id])=> id === value);

        if (typeof choice !== 'undefined') {
            return choice[1];
        }
        else if (allowEmpty) {
            return this.getChoices().find(([id])=> id === emptyValue)[1];
        }
        else {
            return value;
        };
    }

    handleChange = (event)=> {
        const value = first(this.getChoices()[Number(event.target.value)]);
        this.props.onChange(value, this.props.name);
    };

    handleBlur = (event)=> {
        this.setState({focused: false});
        this.props.onBlur(event, this.props.name);
    };

    handleFocus = (event)=> {
        this.setState({focused: true});
        this.props.onFocus(event, this.props.name);
    };

    handleReference = (input)=> {
        this.input = input; // eslint-disable-line immutable/no-mutation
    };

    renderOptions(options) {
        return options.map(({index, label})=>
            <option key={index} value={index}>{label}</option>
        );
    }

    renderChoices(choices) {
        const {groups} = this.props;
        choices = choices.map(([,label, group], index)=> ({index, label, group}));
        return groups
            ? Object.entries(groupBy(choices, 'group'))
                .map(([group, choices], idx)=> group === '--'
                    ? this.renderOptions(choices)
                    : <optgroup key={idx} label={group}>
                        {this.renderOptions(choices)}
                    </optgroup>
                )
            : this.renderOptions(choices);
    }

    renderPlain(className) {
        const {value, ...props} = this.props;
        const choices = this.getChoices();
        const valueIndex = choices.indexOf(choices.find(([id])=> id === value));
        return (
            <select
                {...pick(props, PASS_PROPS)}
                ref={this.handleReference}
                className={className}
                value={valueIndex}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onChange={this.handleChange} >
                {this.renderChoices(choices)}
            </select>
        );
    }

    renderStyled() {
        const {className, disabled, transparent, inputSize, style} = this.props;
        const wrapCls = classnames(className, `input-${inputSize}`, 'custom-select', {
            'custom-select-focused': this.state.focused,
            'custom-select-disabled': Boolean(disabled),
            'custom-select--transparent': Boolean(transparent),
        });
        return (
            <div className={wrapCls} style={style}>
                {this.renderPlain('form-control')}
                <span className="value">
                    {this.getValueDisplay()}
                </span>
            </div>
        );
    }

    render() {
        const {plain, className} = this.props;
        return plain ? this.renderPlain(className)
            : this.renderStyled();
    }
}
