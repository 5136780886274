const providerCredentialsTypes = {
    SET_CREDENTIALS_LIST: 'SET_CREDENTIALS_LIST',
    SET_LIST: 'SET_TRAVELPORT_CREDS',
    ADD_CREDENTIALS: 'ADD_CREDENTIALS',
    RESET_FORM: 'RESET_FORM',
    SET_COUNT_DEPENDENCIES: 'SET_COUNT_DEPENDENCIES',
    TRAVELPORT_SHOW_MODAL: 'TRAVELPORT_SHOW_MODAL',
    TRAVELPORT_HIDE_MODAL: 'TRAVELPORT_HIDE_MODAL',
    TRAVELPORT_SHOW_ADD_MODAL: 'TRAVELPORT_SHOW_ADD_MODAL',
    TRAVELPORT_ADD_HIDE_MODAL: 'TRAVELPORT_ADD_HIDE_MODAL',
};

export default providerCredentialsTypes;
