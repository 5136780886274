import {combineReducers} from 'redux';
import isNil from 'lodash/isNil';
import * as actionTypes from '../actionTypes';
import {AIR_BOOK_FAIL} from "airborne/store/modules/checkout_air/actionTypes";

const defaultCancellation = {
    loading: false,
    errors: null,
};
function cancellation(state = defaultCancellation, {type, error}) {
    switch (type) {
        case actionTypes.AIR_BOOKING_CANCEL_LOADING:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.AIR_BOOKING_CANCEL_LOADED:
            return {
                errors: null,
                loading: false,
            };
        case actionTypes.AIR_BOOKING_CANCEL_FAIL:
            return {
                error,
                loading: false,
            };
        default:
            return state;
    }
}

const detailsDefaults = {
    detailsLoading: false,
    refreshDataLoading: false,
    data: null,
};

function details(state, {type, data, ticketIndex = 0}) {
    if (!state) {
        return {
            0: detailsDefaults,
            1: detailsDefaults,
        };
    }
    if (type === actionTypes.AIR_BOOKING_DETAILS_LOADED) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                data,
                detailsLoading: false,
                refreshDataLoading: false,
            }
        };
    }
    if (type === actionTypes.AIR_BOOKING_DETAILS_LOADING) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                detailsLoading: true,
            }
        };
    }
    if (type === actionTypes.AIR_BOOKING_REFRESH_DATA_LOADING) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                refreshDataLoading: true,
            }
        };
    }

    if (type === AIR_BOOK_FAIL && !isNil(ticketIndex)) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                detailsLoading: false,
                refreshDataLoading: false,
            }
        }
    }

    return state;
}

const airBookingDetails = combineReducers({
    cancellation,
    details,
});

export default airBookingDetails;
