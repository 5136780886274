import get from 'lodash/get';
import inRange from 'lodash/inRange';

function compare(valueA, valueB) {
    if (valueA && valueA.diff) {
        return valueA.diff(valueB);
    }

    if (valueB && valueB.diff) {
        return -1; // null is lesser than date
    }

    if (valueA && valueA.localeCompare) {
        return valueA.localeCompare(valueB);
    }

    if (valueA > valueB) {
        return 1;
    }

    if (valueA < valueB) {
        return -1;
    }

    return 0;
}

function intersect(value, filterValue) {
    if (!filterValue || !filterValue.length) {
        return true;
    }

    return filterValue.reduce(
        (acc, oneValue)=> (
            acc || value.includes(oneValue)
        ),
        false
    );
}

function intersectAnd(value, filterValue) {
    if (!filterValue || !filterValue.length) {
        return true;
    }

    return filterValue.reduce(
        (acc, oneValue)=> (
            acc && value.includes(oneValue)
        ),
        true
    );
}

function filterHelper(obj, field, op, value, matchNull) {
    const fieldValue = get(obj, field);
    if (value === null && op !== 'inverse') {
        return true;
    }

    if (fieldValue === null) {
        return Boolean(matchNull);
    }

    if (op === 'gte') {
        return compare(fieldValue, value) >= 0;
    }

    if (op === 'lte') {
        return compare(fieldValue, value) <= 0;
    }

    if (op === 'gt') {
        return compare(fieldValue, value) > 0;
    }

    if (op === 'lt') {
        return compare(fieldValue, value) < 0;
    }

    if (op === 'eq') {
        return compare(fieldValue, value) === 0;
    }

    if (op === 'in') {
        if (!value.length) {
            return true;
        }
        return value.includes(fieldValue);
    }

    if (op === 'intersect') {
        return intersect(fieldValue, value);
    }

    if (op === 'intersectAnd') {
        return intersectAnd(fieldValue, value);
    }

    if (op === 'includes') {
        return fieldValue.toLowerCase().includes(value.toLowerCase());
    }

    if (op === 'inverse') {
        return value ? true : fieldValue === false ;
    }
    if (op === 'match') {
        return Boolean(fieldValue.match(value));
    }

    if (op === 'someInRange' && Array.isArray(fieldValue)) {
        const [min = -Infinity, max = Infinity] = value;

        return fieldValue.some(oneOfValues => {
            return inRange(oneOfValues, min, max);
        });
    }

    if (op === 'hasAtLeastOneTrue' && value && value.length > 0) {
        return Object.values(fieldValue).some(value => Boolean(value));
    }

    return true;

}

export default function applyFilters(
    data,
    filters,
    exclusionCheck,
) {
    return filters.reduce(
        (acc, filter)=> (
            acc.filter((obj)=>
                exclusionCheck?.(obj)
                    ? data
                    : filterHelper(obj, filter.field, filter.op, filter.value, filter.matchNull))
        ),
        data
    );
}
