import {createSchema, required} from 'midoffice/newforms/helpers';
import {Field} from 'midoffice/newforms/fields-stateless';

export default function createAddCredsSchema(names) {
    return createSchema({
        fields: {
            provider: required(Field),
            name: required({
                ...Field,
                validate(value) {
                    const uniqError = names.includes(value) ? 'Please, change the name. Name should be unique.' : null;
                    if (uniqError) {
                        return uniqError;
                    }
                    return this.validateEmptyValue(value);
                },
            }),
            url: required(Field),
            username: Field,
            password: required(Field),
            description: Field,
            comment: Field,
            'auth_url': Field,
        },
    });
}
