'process i18n';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import Autocomplete from 'midoffice/newforms/widgets/Autocomplete';
import Textarea from 'midoffice/newforms/widgets/Textarea';
import Input from 'midoffice/newforms/widgets/Input';
import SecretInput from 'midoffice/newforms/widgets/SecretInput';
import Modal from 'react-bootstrap/Modal';
import Button from 'midoffice/components/Button';
import Field from 'midoffice/newforms/components/Field';
import modals from 'midoffice/modals';
import MyForm from 'midoffice/newforms/MyForm';

import {
    hideAddModal,
    createCred,
    updateCred,
} from 'midoffice/store/modules/providerCredentials/actions';
import {
    getCredentialsById,
    getCurrentId,
    getList
} from 'midoffice/store/modules/providerCredentials/selectors';
import isNumber from 'lodash/isNumber';
import createAddCredsSchema from './AddCredsSchema';
import {ALL_PROVIDERS, getProviderLabel, getProviderValue} from './helpers';

const providerAutocomplete = {
    query: (query) => {
        const STATIC_PROVIDERS = ALL_PROVIDERS.map(([, label]) => ({value: label, label}));
        const filteredProviders = STATIC_PROVIDERS.filter((provider) => provider.label.toLowerCase().includes(query.toLowerCase()));
        const hasExactMatch = filteredProviders.some((provider) => provider.label.toLowerCase() === query.toLowerCase());

        return Promise.resolve(
            hasExactMatch ? filteredProviders : [
                ...filteredProviders,
                {
                    label: `Custom: ${query}`,
                    value: query,
                },
            ]
        );
    },

    empty: () => {
        const STATIC_PROVIDERS = ALL_PROVIDERS.map(([, label]) => ({value: label, label}));
        return Promise.resolve(STATIC_PROVIDERS);
    }
};


const parseFormValue = ({provider, ...rest}) => ({...rest, provider: getProviderLabel(provider)});
const serializeFormValue = ({provider, ...rest}) => ({...rest, provider: getProviderValue(provider)});

@connect((state) => {
    const currentId = getCurrentId(state);
    return {
        credsList: getList(state) || [],
        currentCred: getCredentialsById(state, currentId),
        currentId,
    };
}, {
    onHide: hideAddModal,
    createCred,
    updateCred,
})
export default class AddCredsModal extends React.Component {
    static propTypes = {
        credsList: PropTypes.array,
        currentId: PropTypes.number,
        currentCred: PropTypes.object,
        onHide: PropTypes.func.isRequired,
        createCred: PropTypes.func.isRequired,
        updateCred: PropTypes.func.isRequired,
    };

    state = {
        isOnProcessing: false,
    };

    getInitialValue = () => {
        return parseFormValue({
            name: this.props?.currentCred?.name || '',
            username: this.props?.currentCred?.username || '',
            password: this.props?.currentCred?.password || '',
            provider: this.props?.currentCred?.provider || '',
            url: this.props?.currentCred?.url || '',
            'auth_url': this.props?.currentCred?.auth_url || '',
            comment: this.props?.currentCred?.comment || '',
        });
    };

    shouldShouldError = (formikProps, field) => {
        const {touched, errors} = formikProps;
        return Boolean(touched[field]) && Boolean(errors[field]);
    };

    getError = (formikProps, field) => {
        return formikProps.touched[field] ? formikProps.errors[field] : null;
    };

    validate = (values) => {
        const {currentId, credsList} = this.props;
        const otherNames = credsList.filter((creds) => creds.localId !== currentId).map((creds) => creds.name);
        const schema = createAddCredsSchema(otherNames);
        const errors = schema.validate(values) || {};
        return errors;
    };

    handleSave = (values) => {
        const {currentId, credsList, onHide, createCred, updateCred} = this.props;
        const hasId = isNumber(currentId);
        const cred = hasId ? {...credsList[currentId], ...values} : values;
        const sendMethod = hasId ? updateCred : createCred;
        this.setState({isOnProcessing: true});
        sendMethod(serializeFormValue(cred))
            .then(() => {
                modals.alert('Changes saved');
            })
            .catch(() => {
                modals.alert('Failed to save changes', 'danger');
            })
            .finally(() => {
                this.setState({isOnProcessing: false});
                onHide();
            });
    }

    handleProviderCommit = (formProps) => ({value}) => {
        formProps.handleChange(value, 'provider');
    };

    render() {
        const {isOnProcessing} = this.state;
        const {onHide} = this.props;
        return (
            <Modal show onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Credentials')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <MyForm
                        initialValues={this.getInitialValue()}
                        formId="add-creds-form"
                        validate={this.validate}
                        onSubmit={this.handleSave}
                    >
                        {(props) => {
                            const extraProps = {
                                onFocus: props.handleFocus,
                                onBlur: props.handleBlur,
                                onChange: props.handleChange,
                                inputCol: 7,
                            };

                            return (
                                <div className="form-horizontal">
                                    <Field
                                        label="Provider"
                                        name="provider"
                                        value={props.values['provider']}
                                        widget={Autocomplete}
                                        required
                                        autocompleteSource={providerAutocomplete}
                                        showErrorMessage={this.shouldShouldError(props, 'provider')}
                                        errors={this.getError(props, 'provider')}
                                        {...extraProps}
                                        onCommit={this.handleProviderCommit(props)}
                                    />

                                    <Field
                                        label="Name"
                                        name="name"
                                        value={props.values['name']}
                                        hint="The name should be a short summary of the creds set that will be visible at the client configuration level, and it should provide a clear understanding and differentiation between the sets"
                                        widget={Input}
                                        required
                                        showErrorMessage={this.shouldShouldError(props, 'name')}
                                        errors={this.getError(props, 'name')}
                                        {...extraProps}
                                    />

                                    <Field
                                        label="URL"
                                        name="url"
                                        value={props.values['url']}
                                        widget={Input}
                                        required
                                        showErrorMessage={this.shouldShouldError(props, 'url')}
                                        errors={this.getError(props, 'url')}
                                        {...extraProps}
                                    />

                                    <Field
                                        label="Username"
                                        name="username"
                                        value={props.values['username']}
                                        hint="This is a public identifier of creds sets, i.e. it is one of 'username', 'user id', 'API key ID', 'consumer identifier' etc."
                                        widget={Input}
                                        {...extraProps}
                                    />

                                    <Field
                                        label="Password"
                                        name="password"
                                        value={props.values['password']}
                                        hint="This field holds the secret"
                                        widget={SecretInput}
                                        required
                                        errors={this.getError(props, 'password')}
                                        {...extraProps}
                                    />

                                    <Field
                                        label="Auth URL"
                                        name="auth_url"
                                        value={props.values['auth_url']}
                                        widget={Input}
                                        {...extraProps}
                                    />

                                    <Field
                                        label="Comment"
                                        name="comment"
                                        value={props.values['comment']}
                                        widget={Textarea}
                                        {...extraProps}
                                    />
                                </div>
                            );
                        }}
                    </MyForm>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="light" onClick={onHide}>{gettext('Close')}</Button>
                    <Button
                        bsStyle="success"
                        form="add-creds-form"
                        type="submit"
                        progress={isOnProcessing}
                    >
                        {gettext('Save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
