export const NEED_TO_PERMISSIONS = {
    'section:company': [
        'midoffice:companies:tree:edit',
        'midoffice:companies:general:company_settings:view',
        'midoffice:companies:general:company_settings:edit',
        'midoffice:companies:general:company_ids:view',
        'midoffice:companies:general:company_ids:edit',
        'midoffice:companies:general:company_locations:view',
        'midoffice:companies:general:company_locations:edit',
        'midoffice:companies:general:company_access:view',
        'midoffice:companies:general:company_access:edit',
        'midoffice:companies:general:primary_gds:view',
        'midoffice:companies:general:primary_gds:edit',
        'midoffice:companies:general:queues:view',
        'midoffice:companies:general:queues:edit',
        'midoffice:companies:general:aft_configuration:view',
        'midoffice:companies:general:aft_configuration:edit',
        'midoffice:companies:general:tspm_profile_sync:view',
        'midoffice:companies:general:tspm_profile_sync:edit',
        'midoffice:companies:general:tspm_connection_on_off:view',
        'midoffice:companies:general:tspm_connection_on_off:edit',
        'midoffice:companies:general:client_reportable_data:view',
        'midoffice:companies:general:client_reportable_data:edit',
        'midoffice:companies:general:guest_travelers:view',
        'midoffice:companies:general:guest_travelers:edit',
        'midoffice:companies:general:default_display_preferences:view',
        'midoffice:companies:general:default_display_preferences:edit',
        'midoffice:companies:general:configuration_export:view',
        'midoffice:companies:general:configuration_export:edit',
        'midoffice:companies:general:password_session_management:view',
        'midoffice:companies:general:password_session_management:edit',
        'midoffice:companies:general:terms_conditions:view',
        'midoffice:companies:general:terms_conditions:edit',
        'midoffice:companies:general:features:view',
        'midoffice:companies:general:features:edit',
        'midoffice:companies:general:vpa_settings:view',
        'midoffice:companies:general:vpa_settings:edit',
        'midoffice:companies:hotels:pnr_formatting:view',
        'midoffice:companies:hotels:pnr_formatting:edit',
        'midoffice:companies:hotels:gds_segment_configuration:view',
        'midoffice:companies:hotels:gds_segment_configuration:edit',
        'midoffice:companies:hotels:booking_com_credentials:view',
        'midoffice:companies:hotels:booking_com_credentials:edit',
        'midoffice:companies:hotels:eps_credentials:view',
        'midoffice:companies:hotels:eps_credentials:edit',
        'midoffice:companies:hotels:spc_credentials:view',
        'midoffice:companies:hotels:spc_credentials:edit',
        'midoffice:companies:hotels:hcorpo_credentials:view',
        'midoffice:companies:hotels:hcorpo_credentials:edit',
        'midoffice:companies:hotels:hrs_credentials:view',
        'midoffice:companies:hotels:hrs_credentials:edit',
        'midoffice:companies:hotels:hrsd_credentials:view',
        'midoffice:companies:hotels:hrsd_credentials:edit',
        'midoffice:companies:hotels:premier_inn_credentials:view',
        'midoffice:companies:hotels:premier_inn_credentials:edit',
        'midoffice:companies:hotels:totalstay_credentials:view',
        'midoffice:companies:hotels:totalstay_credentials:edit',
        'midoffice:companies:hotels:rate_caps:view',
        'midoffice:companies:hotels:rate_caps:edit',
        'midoffice:companies:hotels:company_preferred_hotels:view',
        'midoffice:companies:hotels:company_preferred_hotels:edit',
        'midoffice:companies:hotels:hotel_sorting:view',
        'midoffice:companies:hotels:hotel_sorting:edit',
        'midoffice:companies:hotels:hotel_exclusions:view',
        'midoffice:companies:hotels:hotel_exclusions:edit',
        'midoffice:companies:hotels:hotel_exclusion_rules:view',
        'midoffice:companies:hotels:hotel_exclusion_rules:edit',
        'midoffice:companies:hotels:hotel_labeling:view',
        'midoffice:companies:hotels:hotel_labeling:edit',
        'midoffice:companies:hotels:company_preferred_rate_codes:view',
        'midoffice:companies:hotels:company_preferred_rate_codes:edit',
        'midoffice:companies:hotels:rate_exclusions:view',
        'midoffice:companies:hotels:rate_exclusions:edit',
        'midoffice:companies:hotels:rate_labeling:view',
        'midoffice:companies:hotels:rate_labeling:edit',
        'midoffice:companies:hotels:rate_optimization:view',
        'midoffice:companies:hotels:rate_optimization:edit',
        'midoffice:companies:hotels:special_rates:view',
        'midoffice:companies:hotels:special_rates:edit',
        'midoffice:companies:hotels:advertising:view',
        'midoffice:companies:hotels:advertising:edit',
        'midoffice:companies:hotels:autocomplete:view',
        'midoffice:companies:hotels:autocomplete:edit',
        'midoffice:companies:hotels:filters:view',
        'midoffice:companies:hotels:filters:edit',
        'midoffice:companies:hotels:images_media_content:view',
        'midoffice:companies:hotels:images_media_content:edit',
        'midoffice:companies:hotels:trustyou:view',
        'midoffice:companies:hotels:trustyou:edit',
        'midoffice:companies:hotels:bcd_merchant_content:view',
        'midoffice:companies:hotels:bcd_merchant_content:edit',
        'midoffice:companies:cars:cars:view',
        'midoffice:companies:cars:cars:edit',
        'midoffice:companies:cars:pnr_formatting:view',
        'midoffice:companies:cars:pnr_formatting:edit',
        'midoffice:companies:cars:company_negotiated_rates:view',
        'midoffice:companies:cars:company_negotiated_rates:edit',
        'midoffice:companies:cars:company_preferred_rates:view',
        'midoffice:companies:cars:company_preferred_rates:edit',
        'midoffice:companies:cars:company_rate_exclusions:view',
        'midoffice:companies:cars:company_rate_exclusions:edit',
        'midoffice:companies:cars:advertising:view',
        'midoffice:companies:cars:advertising:edit',
        'midoffice:companies:cars:autocomplete:view',
        'midoffice:companies:cars:autocomplete:edit',
        'midoffice:companies:cars:cars_sorting:view',
        'midoffice:companies:cars:cars_sorting:edit',
        'midoffice:companies:flights:flights:view',
        'midoffice:companies:flights:flights:edit',
        'midoffice:companies:flights:pnr_formatting:view',
        'midoffice:companies:flights:pnr_formatting:edit',
        'midoffice:companies:flights:air_policy:view',
        'midoffice:companies:flights:air_policy:edit',
        'midoffice:companies:flights:client_negotiated_fares:view',
        'midoffice:companies:flights:client_negotiated_fares:edit',
        'midoffice:companies:flights:travelfusion:view',
        'midoffice:companies:flights:travelfusion:edit',
        'midoffice:companies:flights:gpos:view',
        'midoffice:companies:flights:gpos:edit',
        'midoffice:companies:flights:advertising:view',
        'midoffice:companies:flights:advertising:edit',
        'midoffice:companies:general:agent_chat:view',
        'midoffice:companies:general:agent_chat:edit',
        'midoffice:companies:general:feature_toggles:view',
        'midoffice:companies:general:sso_configuration:view',
        'midoffice:companies:general:sso_configuration:edit',
        'midoffice:companies:general:invoice_collection:view',
        'midoffice:companies:flights:co2_emissions:view',
        'midoffice:companies:flights:fop_extension:view',
        'midoffice:companies:flights:search_customizations:view',
        'midoffice:companies:flights:ssr_messaging:view',
        'midoffice:companies:rail:rail:view',
        'midoffice:companies:rail:trainline:view',
        'midoffice:companies:rail:trainline:edit',
        'midoffice:companies:rail:deutschebahn:view',
        'midoffice:companies:rail:deutschebahn:edit',
        'midoffice:companies:rail:multiple_passengers:view',
        'midoffice:companies:rail:multiple_passengers:edit',
        'midoffice:companies:general:agent_chat:edit',
        'midoffice:companies:general:dbi_airplus:view',
        'midoffice:companies:general:dbi_airplus:edit',
        'midoffice:companies:general:dbi_amex:view',
        'midoffice:companies:general:dbi_amex:edit',
        'midoffice:companies:general:feature_toggles:edit',
        'midoffice:companies:general:pnr_formatting:view',
        'midoffice:companies:general:pnr_formatting:edit',
        'midoffice:companies:general:invoice_collection:edit',
        'midoffice:companies:hotels:omnibees_credentials:view',
        'midoffice:companies:hotels:omnibees_credentials:edit',
        'midoffice:companies:hotels:company_secured_hotels:view',
        'midoffice:companies:hotels:company_secured_hotels:edit',
        'midoffice:companies:hotels:dynamic_sort:view',
        'midoffice:companies:hotels:dynamic_sort:edit',
        'midoffice:companies:hotels:travel_restrictions:view',
        'midoffice:companies:hotels:travel_restrictions:edit',
        'midoffice:companies:hotels:vat_settings:view',
        'midoffice:companies:hotels:vat_settings:edit',
        'midoffice:companies:hotels:payment_methods_priority:view',
        'midoffice:companies:hotels:payment_methods_priority:edit',
        'midoffice:companies:cars:travel_policy:view',
        'midoffice:companies:cars:travel_policy:edit',
        'midoffice:companies:cars:cars_labeling:view',
        'midoffice:companies:cars:cars_labeling:edit',
        'midoffice:companies:cars:payment_methods_priority:view',
        'midoffice:companies:cars:payment_methods_priority:edit',
        'midoffice:companies:cars:travel_restrictions:view',
        'midoffice:companies:cars:travel_restrictions:edit',
        'midoffice:companies:cars:segment_configuration:view',
        'midoffice:companies:cars:segment_configuration:edit',
        'midoffice:companies:flights:air_labeling:view',
        'midoffice:companies:flights:air_labeling:edit',
        'midoffice:companies:flights:co2_emissions:edit',
        'midoffice:companies:flights:custom_airport_hubs:view',
        'midoffice:companies:flights:custom_airport_hubs:edit',
        'midoffice:companies:flights:british_airways:view',
        'midoffice:companies:flights:british_airways:edit',
        'midoffice:companies:flights:lufthansa:view',
        'midoffice:companies:flights:lufthansa:edit',
        'midoffice:companies:flights:preferred_airlines:view',
        'midoffice:companies:flights:preferred_airlines:edit',
        'midoffice:companies:flights:exclusions:view',
        'midoffice:companies:flights:exclusions:edit',
        'midoffice:companies:flights:travel_restrictions:view',
        'midoffice:companies:flights:travel_restrictions:edit',
        'midoffice:companies:flights:osi_messaging:view',
        'midoffice:companies:flights:osi_messaging:edit',
        'midoffice:companies:flights:fop_extension:edit',
        'midoffice:companies:flights:search_customizations:edit',
        'midoffice:companies:flights:search_sorting_customization:view',
        'midoffice:companies:flights:search_sorting_customization:edit',
        'midoffice:companies:flights:ssr_messaging:edit',
        'midoffice:companies:flights:sabre_ndc:view',
        'midoffice:companies:flights:sabre_ndc:edit',
        'midoffice:companies:rail:rail:edit',
        'midoffice:companies:overrides:view',
        'midoffice:companies:overrides:edit',
    ],
    'section:branch': [
        'midoffice:agencies:tree:edit',
        'midoffice:agencies:general:agency_contact_info:view',
        'midoffice:agencies:general:agency_contact_info:edit',
        'midoffice:agencies:general:custom_geographic_groups:view',
        'midoffice:agencies:general:custom_geographic_groups:edit',
        'midoffice:agencies:general:agency_booking_support_email:view',
        'midoffice:agencies:general:agency_booking_support_email:edit',
        'midoffice:agencies:general:agentsource_desktop_integration:view',
        'midoffice:agencies:general:agentsource_desktop_integration:edit',
        'midoffice:agencies:general:amadeus_credentials:view',
        'midoffice:agencies:general:amadeus_credentials:edit',
        'midoffice:agencies:general:sabre_credentials:view',
        'midoffice:agencies:general:sabre_credentials:edit',
        'midoffice:agencies:general:apollo_credentials:view',
        'midoffice:agencies:general:apollo_credentials:edit',
        'midoffice:agencies:general:galileo_credentials:view',
        'midoffice:agencies:general:galileo_credentials:edit',
        'midoffice:agencies:general:password_session_management:view',
        'midoffice:agencies:general:password_session_management:edit',
        'midoffice:agencies:general:default_display_preferences:view',
        'midoffice:agencies:general:default_display_preferences:edit',
        'midoffice:agencies:general:terms_conditions:view',
        'midoffice:agencies:general:terms_conditions:edit',
        'midoffice:agencies:general:custom_countries_of_fulfillment:view',
        'midoffice:agencies:general:custom_countries_of_fulfillment:edit',
        'midoffice:agencies:hotels:bcd_travel_preferred_hotels:view',
        'midoffice:agencies:hotels:bcd_travel_preferred_hotels:edit',
        'midoffice:agencies:hotels:bcd_travel_preferred_rate_codes:view',
        'midoffice:agencies:hotels:bcd_travel_preferred_rate_codes:edit',
        'midoffice:agencies:hotels:agency_hotel_exclusions:view',
        'midoffice:agencies:hotels:agency_hotel_exclusions:edit',
        'midoffice:agencies:hotels:branch_hotel_exclusions:view',
        'midoffice:agencies:hotels:branch_hotel_exclusions:edit',
        'midoffice:agencies:hotels:rate_exclusions:view',
        'midoffice:agencies:hotels:rate_exclusions:edit',
        'midoffice:agencies:hotels:rate_optimization:view',
        'midoffice:agencies:hotels:rate_optimization:edit',
        'midoffice:agencies:hotels:search_engine_configuration:view',
        'midoffice:agencies:hotels:search_engine_configuration:edit',
        'midoffice:agencies:hotels:rate_overrides:view',
        'midoffice:agencies:hotels:rate_overrides:edit',
        'midoffice:agencies:hotels:mandatory_pnr_remarks_template:view',
        'midoffice:agencies:hotels:mandatory_pnr_remarks_template:edit',
        'midoffice:agencies:hotels:passive_segment_pnr_templates:view',
        'midoffice:agencies:hotels:passive_segment_pnr_templates:edit',
        'midoffice:agencies:hotels:revenue_management:view',
        'midoffice:agencies:hotels:revenue_management:edit',
        'midoffice:agencies:cars:mandatory_pnr_remarks_template:view',
        'midoffice:agencies:cars:mandatory_pnr_remarks_template:edit',
        'midoffice:agencies:cars:agency_configuration:view',
        'midoffice:agencies:cars:agency_configuration:edit',
        'midoffice:agencies:cars:agency_negotiated_rates:view',
        'midoffice:agencies:cars:agency_negotiated_rates:edit',
        'midoffice:agencies:cars:agency_preferred_rates:view',
        'midoffice:agencies:cars:agency_preferred_rates:edit',
        'midoffice:agencies:cars:agency_rate_exclusions:view',
        'midoffice:agencies:cars:agency_rate_exclusions:edit',
        'midoffice:agencies:flights:bcd_negotiated_fares:view',
        'midoffice:agencies:flights:bcd_negotiated_fares:edit',
        'midoffice:agencies:flights:mandatory_pnr_remarks_template:view',
        'midoffice:agencies:flights:mandatory_pnr_remarks_template:edit',
        'midoffice:agencies:flights:passive_segment_configuration:view',
        'midoffice:agencies:flights:passive_segment_configuration:edit',
        'midoffice:agencies:flights:farelogix_credentials:view',
        'midoffice:agencies:flights:farelogix_credentials:edit',
        'midoffice:agencies:general:invoice_collection:view',
        'midoffice:agencies:hotels:backoffice_send:view',
        'midoffice:agencies:cars:backoffice_send:view',
        'midoffice:agencies:flights:lcc_corporate_credentials:view',
        'midoffice:agencies:flights:policy:view',
        'midoffice:agencies:general:mandatory_pnr_remarks_template:view',
        'midoffice:agencies:general:mandatory_pnr_remarks_template:edit',
        'midoffice:agencies:general:invoice_collection:edit',
        'midoffice:agencies:hotels:backoffice_send:edit',
        'midoffice:agencies:cars:backoffice_send:edit',
        'midoffice:agencies:flights:policy:edit',
        'midoffice:agencies:flights:gds_ticketing_configuration:view',
        'midoffice:agencies:flights:gds_ticketing_configuration:edit',
        'midoffice:agencies:flights:lcc_corporate_credentials:edit',
        'midoffice:agencies:flights:preferred_airlines:view',
        'midoffice:agencies:flights:preferred_airlines:edit',
        'midoffice:agencies:flights:exclusions:view',
        'midoffice:agencies:flights:exclusions:edit',
    ],
    'section:aft-messaging': ['midoffice:tools:aft_messaging:edit'],
    'modify_company_tree': ['midoffice:companies:tree:edit'],
    'modify_agency_tree': ['midoffice:agencies:tree:edit'],
    'section:logs': ['midoffice:auditlogs:view'],
    'section:booking': ['aft:booking_records:edit'],
    'section:fasttrace': ['midoffice:tools:fasttrace:view'],
    'section:gds-console': ['midoffice:tools:qa_tools:view'],
    'section:pnr': ['midoffice:tools:pnr_tools:view'],
    'section:flags': ['midoffice:tools:flags:view'],
    'section:naboo': ['midoffice:tools:naboo:view'],
    'section:description-labeling': ['midoffice:tools:bed_room_labeling:edit'],
    'tools:gds_auth_details': ['midoffice:tools:gds_auth_details:edit', 'midoffice:tools:gds_auth_details:view'],
    'user:admins': [
        'midoffice:groups:product_manager:view',
        'midoffice:groups:admin:view',
        'midoffice:groups:advertising_manager:view',
        'midoffice:groups:booking_admin:view',
        'midoffice:groups:revenue_manager:view',
        'midoffice:groups:pnr_admin:view',
        'midoffice:groups:site_admin:view',
        'midoffice:groups:supplier_relations:view',
        'midoffice:api_keys:view',
    ],
    'user:agents': ['midoffice:groups:agent:view'],
    'section:user': [
        'midoffice:groups:agent:view',
        'midoffice:groups:product_manager:view',
        'midoffice:groups:admin:view',
        'midoffice:groups:advertising_manager:view',
        'midoffice:groups:booking_admin:view',
        'midoffice:groups:revenue_manager:view',
        'midoffice:groups:pnr_admin:view',
        'midoffice:groups:site_admin:view',
        'midoffice:groups:supplier_relations:view',
        'midoffice:groups:user_without_group:view',
        'midoffice:api_keys:view',
    ],
    'user:super_admins': ['midoffice:groups:admin:view'],
    'user:site_admin': ['midoffice:groups:site_admin:view'],
    'user:advertising_manager': ['midoffice:groups:advertising_manager:view'],
    'user:supplier_relations': ['midoffice:groups:supplier_relations:view'],
    'user:product_manager': ['midoffice:groups:product_manager:view'],
    'user:booking_admin': ['midoffice:groups:booking_admin:view'],
    'user:pnr_admin': ['midoffice:groups:pnr_admin:view'],
    'user:revenue_manager': ['midoffice:groups:revenue_manager:view'],
};

export const PERMISSION_TO_FILTER = [
    // 'midoffice:groups:product_manager:view',
    // 'midoffice:groups:admin:view',
    // 'midoffice:groups:advertising_manager:view',
    // 'midoffice:groups:booking_admin:view',
    // 'midoffice:groups:revenue_manager:view',
    // 'midoffice:groups:pnr_admin:view',
    // 'midoffice:groups:site_admin:view',
    // 'midoffice:groups:supplier_relations:view',
    //
    // 'midoffice:groups:agent:view',
    //edit
    // 'midoffice:groups:product_manager:edit',
    // 'midoffice:groups:admin:edit',
    // 'midoffice:groups:advertising_manager:edit',
    // 'midoffice:groups:booking_admin:edit',
    // 'midoffice:groups:revenue_manager:edit',
    // 'midoffice:groups:pnr_admin:edit',
    // 'midoffice:groups:site_admin:edit',
    // 'midoffice:groups:supplier_relations:edit',
    // 'midoffice:groups:agent:edit',
];
