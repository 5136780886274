import settings from 'airborne/settings';
import moment from 'moment';

export const ALL_PROVIDERS = [
    ['bookingcom', 'Booking.com'],
    ['eps', 'EPS'],
    ['spc', 'Stay Private Channel'],
    ['hcorpo', 'HCorpo'],
    ['hrs', 'HRS'],
    ['hrsd', 'HRS Direct'],
    ['omnibees', 'OmniBees'],
    ['premierinn', 'Premier Inn'],
    ['totalstay', 'Totalstay'],
    ['totalstay_blc', 'BLC (TTS)'],
    ['amadeus', 'Amadeus'],
    ['sabre', 'Sabre'],
    ['travelport', 'Travelport(Apollo/Gallileo)'],
    ['ba', 'British Airways'],
    ['farelogix', 'Farelogix'],
    ['travelfusion', 'Travelfusion'],
    ['tspm', 'TSPM'],
    ['conferma', 'Conferma'],
    ['itelya', 'Itelya'],
    ['troovo', 'Troovo'],
    ['airplus', 'Airplus'],
    ['trainline', 'Trainline'],
    ['deutschebahn', 'Deutschebahn'],
    ['bcdapi', 'TDP API (Travel data platform / BCD API)'],
    ['bcdtokenizer', 'BCD Tokenizer'],
    ['routehappy', 'Routehappy'],
    ['travel_impact_model', 'Travel Impact Model'],
    ['bcdsso', 'BCD SSO'],
    ['contentful', 'Contentful'],
    ['tdl_admin', 'TDL Admin'],
    ['tiam', 'TIAM'],
];

export function getProviderLabel(value) {
    return ALL_PROVIDERS.find(([val]) => val === value)?.[1] || value;
}

export function getProviderValue(label) {
    return ALL_PROVIDERS.find(([, lab]) => lab === label)?.[0] || label;
}

export function formatDate(date) {
    return `${moment(date).format(settings.USER.date_format_str)} ${moment(date).format(settings.TIME_FORMAT)}`;
}
