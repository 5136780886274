import forms from 'midoffice/newforms';
import settings from 'airborne/settings';
import {hasAccess} from 'midoffice/helpers/permission';
import CountryAutocomplete from 'midoffice/data/CountryAutocomplete';
import CountryTags from 'midoffice/newforms/widgets/CountryTags';

import {CompanyField, DateRangeField} from './filterFields';

const STATES = [
    ['1', 'New'],
    ['2', 'Active'],
    ['4', 'Locked'],
    ['5', 'Invited'],
    ['3', 'Suspended']
];

/* This table and field name
   should be changed after Yura would finish GG-4733 */
export const ROLES = [
    ['s', 'Admin', 'Has access to everything, except bookings made by companies that have the Restrict Booking Access setting enabled and edit Mandatory PNR Remarks Template section in Admin'],
    ['r', 'Supplier Relations', 'Has access to GDS/supplier configurations, hotels, rates, reporting, and advertising'],
    ['d', 'Advertising Manager', 'Has access to advertising'],
    ['i', 'Site Admin', 'Has access to everything, except user provisioning for administrators'],
    ['m', 'Product Manager', 'Has access to subset of sections on Companies tab'],
    ['e', 'Revenue Manager', 'Can manage brand preferences'],
];

const YN = [
    ['1', 'Yes'],
    ['0', 'No']
];

const ROLE_PERMISSIONS = {
    t: 'user:travelers',
    s: 'user:super_admins',
    i: 'user:site_admin',
    d: 'user:advertising_manager',
    r: 'user:supplier_relations',
    a: 'user:agents',
    m: 'user:product_manager',
    b: 'user:booking_admin',
    p: 'user:pnr_admin',
    e: 'user:revenue_manager',
};


export function canCreate(role) {
    return hasAccess(ROLE_PERMISSIONS[role]);
}


export function roleName(code) {
    let match = ROLES.filter(([roleCode,])=> roleCode === code);
    if (!match.length) {
        return null;
    }
    let [, matchName] = match[0];
    return matchName;
}


/*eslint-disable camelcase*/
export class UserFilter extends forms.Schema {
    fields = {
        status: new forms.MultipleChoiceField({
            required: false,
            label: 'Status',
            choices: STATES,
        }),
        role: new forms.MultipleChoiceField({
            required: false,
            label: 'Role',
            choices: ROLES,
        }),
        search: new forms.CharField({
            required: false,
        }),
        team: new forms.CharField({
            required: false,
            label: 'Teams',
            placeholder: 'Enter one or more teams'
        }),
        agency_id: new CompanyField({
            required: false,
            label: 'Primary Office',
            placeholder: 'Enter primary office location',
            props: {
                treeName: 'agencies',
                keyField: 'id',
                modalLabel: 'Filter by Agencies',
                linkText: 'Add Agency from Hierarchy...',
            },
        }),
        company_id: new CompanyField({
            required: false,
            label: 'Company',
            placeholder: 'Enter one or more companies',
            props: {
                treeName: 'companies',
                keyField: 'id',
                modalLabel: 'Filter by Companies',
                linkText: 'Add Company from Hierarchy...',
            },
        }),
        allowed_companies: new CompanyField({
            required: false,
            label: 'Allowed Companies',
            placeholder: 'Enter one or more companies',
            props: {
                treeName: 'companies',
                keyField: 'id',
                modalLabel: 'Filter by Companies',
                linkText: 'Add Company from Hierarchy...',
            },
        }),
        allowed_countries: new forms.Field({
            required: false,
            allowEmpty: true,
            label: 'Allowed Countries',
            placeholder: 'Enter one or more countries',
            props: {
                autocompleteSource: new CountryAutocomplete(),
            },
            widget: CountryTags,
        }),
        created_ts: new DateRangeField({
            required: false,
            label: 'Date Created',
            props: {
                inputFormat: settings.USER.date_format_str
            }

        }),
        last_login: new DateRangeField({
            required: false,
            label: 'Last Activity',
            props: {
                inputFormat: settings.USER.date_format_str
            }
        }),
    }
}
/*eslint-enable camelcase*/
