import providerCredentialsTypes from 'midoffice/store/modules/providerCredentials/actionTypes';

export default function providerCredentials(state = {}, {credentials, type, count, id}) {
    switch (type) {
        case providerCredentialsTypes.SET_CREDENTIALS_LIST:
            return {
                list: [...credentials],
                currentId: null,
            };
        case providerCredentialsTypes.SET_COUNT_DEPENDENCIES:
            return {
                ...state,
                countDependencies: count,
            };
        case providerCredentialsTypes.TRAVELPORT_SHOW_MODAL:
            return {
                ...state,
                showModal: true,
                currentId: id,
            };
        case providerCredentialsTypes.TRAVELPORT_HIDE_MODAL:
            return {
                ...state,
                showModal: false,
                currentId: null,
            };
        case providerCredentialsTypes.TRAVELPORT_SHOW_ADD_MODAL:
            return {
                ...state,
                showAddModal: true,
                currentId: id,
            };
        case providerCredentialsTypes.TRAVELPORT_ADD_HIDE_MODAL:
            return {
                ...state,
                showAddModal: false,
                currentId: null,
            };
        default:
            return state;
    }
}
