import {createSelector} from 'reselect';
import {getFareRulesTabs} from 'airborne/search_air/helpers/fareRules';
import {getTicketIndexes} from "airborne/pricing_air/helpers/separatedTickets";

function base(state) {
    return state.airBookingDetails;
}

function details(state, ticketIndex = 0) {
    return base(state).details[ticketIndex];
}

export function detailsByTickets(state) {
    return base(state).details;
}

function cancellation(state) {
    return base(state).cancellation;
}

export function isCancellationLoading(state) {
    return cancellation(state).loading;
}

export function getCancellationError(state) {
    return cancellation(state).error;
}

export function isBookingDetailsLoading(state, ticketIndex) {
    return details(state, ticketIndex).detailsLoading;
}

export function getBooking(state, ticketIndex) {
    return details(state, ticketIndex).data;
}

export function getAirDestValue(state) {
    return state.dest.air.value;
}

export const getBookingFareRulesTabs = createSelector(
    getBooking,
    (booking) => getFareRulesTabs(booking),
);

export function isSeparatedTicketsBookingDetailsLoading(state) {
    return getTicketIndexes().some(ticketIndex => {
        const booking = detailsByTickets(state)[ticketIndex];
        return !booking || booking.detailsLoading && booking.data?.status !== 'pending'
    })
}
export function isSeparatedTicketsBookingRefreshLoading(state, ticketIndex = 0) {
    return detailsByTickets(state)[ticketIndex]?.refreshDataLoading;
}

export function getSeparatedTicketsBookings(state) {
    return Object.values(detailsByTickets(state)).map(({data}) => data);
}
