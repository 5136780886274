import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {noErrors} from 'midoffice/newforms/helpers';

export default function MyForm({
    formId,
    initialValues,
    className,
    children,
    onSubmit,
    validate,
    validateOnBlur=true,
    validateOnChange=true,
}) {
    const [values, setValues] = useState(initialValues);
    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});
    const [focused, setFocused] = useState(null);

    const touchAllFields = () => {
        const touchedFields = Object.keys(values).reduce((acc, field) => ({...acc, [field]: true}), {});
        setTouched(touchedFields);
    };

    const renderProps = {
        values,
        errors,
        touched,
        focused,
        handleChange: (value, field) => {
            setValues({...values, [field]: value});
            if (validate && validateOnChange) {
                const errors = validate(values);
                setErrors(errors);
            }
        },
        handleFocus: (event, field) => {
            setFocused(field);
        },
        handleBlur: (event, field) => {
            setTouched({...touched, [field]: true});
            setFocused(null);
            if (validate && validateOnBlur) {
                const errors = validate(values);
                setErrors(errors);
            }
        },
        handleSubmit: (event) => {
            event.preventDefault();
            const errors = validate(values);

            setErrors(errors);
            touchAllFields();
            if (noErrors(errors)) {
                onSubmit(values);
            }
        },
    };

    return (
        <form id={formId} className={className} onSubmit={renderProps.handleSubmit}>
            {children(renderProps)}
        </form>
    );
}

MyForm.propTypes = {
    formId: PropTypes.string,
    className: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func,
    validateOnBlur: PropTypes.bool,
    validateOnChange: PropTypes.bool,
};
